import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CompanyConstant } from "../Company/CompanyConstant";

const SCREEN_CONSTANT = CompanyConstant.Header;
export const INITIAL_TARIFF_CODE_BY_COMP_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.COMPANY_CODE,
        field: "companyCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,

    },
    {
        headerName: SCREEN_CONSTANT.COMPANY_NAME,
        field: "companyName",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, [], [], [], cellRenderers);
});
