import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyTariffCodeEntity } from "domain/entity/TariffCode/CompanyTariffCodeEntity";
import _ from "lodash";
import { INITIAL_TARIFF_CODE_BY_COMP_DTL_COL_DEF } from "presentation/constant/TariffCodeByCompany/TariffCodeByCompanyDetailColumnDefinition";
import { useTariffCodeByCompanyVM } from "presentation/hook/TariffCodeByCompany/useTariffCodeByCompanyVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeByCompanyTracked } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const TariffCodeByCompanyDetailTablePanel: React.FC = () => {
    const [tariffCodeState] = useTariffCodeByCompanyTracked();
    const tariffCodeByCompanyVM = useTariffCodeByCompanyVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, detailState } = tariffCodeState;
    const { isSliderOpen, isEditable, selectedRows, currentSelectItem, isRefreshInitData } = tariffCodeState.detailState;
    // const {selecedRows} = detailState;
    let gridRef: any = useRef();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<CompanyTariffCodeEntity[]>(tariffCodeState.companyTariffCodes);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);    

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        tariffCodeByCompanyVM.updateSelectedDetailRows(selectedRows);
    }, [tariffCodeByCompanyVM])

    const handleCloseClick = useCallback(() => {
        tariffCodeByCompanyVM.onCloseDetail();
    }, [tariffCodeByCompanyVM])

    const handleEditClick = useCallback(() => {
        tariffCodeByCompanyVM.onDetailEditClick(currentSelectItem);
    }, [currentSelectItem, tariffCodeByCompanyVM])

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return detailState.selectedRows;
    }, [detailState.selectedRows]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(detailState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, detailState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        tariffCodeByCompanyVM.updateSelectedDetailRows([]);
        setShowMoveCursor(true);
    }, [tariffCodeByCompanyVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(tariffCodeState.companyTariffCodes);
        tariffCodeByCompanyVM.updateSelectedDetailRows([]);
        setShowMoveCursor(false);
    }, [tariffCodeState.companyTariffCodes, tariffCodeByCompanyVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        tariffCodeByCompanyVM.onDetailApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);

                tariffCodeByCompanyVM.updateSelectedDetailRows([]);
                setShowMoveCursor(false);
            }
        })
    }, [messageBarVM, tariffCodeByCompanyVM]);

    const tbBtns = useMemo(() => {
        let btns = [];
        btns.push({
            id: 'onCloseButton',
            icon: 'Icon-cross',
            title: 'Close'
        })

        btns.push({
            id: 'onEditButton',
            icon: 'Icon-pen',
            title: 'Edit',
            disable: selectedRows && selectedRows.length === 1 && !isEditable? false:true
        })

        return btns;
    }, [isEditable, selectedRows])

    const isRowSelectable = useCallback((params: any) => {
        if (isEditable) {
            return false;
        } else {
            return true;
        }
    }, [isEditable]);

    const memoTariffCodeComponentTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="seq" />}
                <div id="myDiv">
                    <NbisTable
                        id='TariffCodeByCompanyDetail-table'
                        headerLabel={currentSelectedRow.companyCode + (currentSelectedRow.companyName?"(" + currentSelectedRow.companyName + ")" :"")}
                        columns={INITIAL_TARIFF_CODE_BY_COMP_DTL_COL_DEF}
                        data={allRows ?? []}
                        headerActionButtons={tbBtns}
                        onCloseButton={handleCloseClick}
                        onEditButton={handleEditClick}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={true}
                        isNewColumnSetting={true}
                        onSelectionChanged={handleSelectionChange}
                        //onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 90px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        showCutCursor={showMoveCursor}
                        handleRowSelectable={isRowSelectable}
                    />
                </div>
            </>);
    }, [allowUpdate, getSelectedRows, rightClickRef, getAllRows, onSaveMove, onMoveCancel, onRefreshRow, showMoveCursor, currentSelectedRow.companyCode,
         currentSelectedRow.companyName, allRows, tbBtns, handleCloseClick, handleEditClick, handleSelectionChange, isRowSelectable])

    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            await Promise.allSettled([
                tariffCodeByCompanyVM.loadDropdownOption(),
                tariffCodeByCompanyVM.onSearchCompanyTariffCode(currentSelectedRow)
            ]).then(() => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }
        isRefreshInitData && initialScreen();
    }, [currentSelectedRow, isRefreshInitData, tariffCodeByCompanyVM])

    useEffect(() => {
        if (initialAllRows && tariffCodeState.companyTariffCodes && !_.isEmpty(tariffCodeState.companyTariffCodes)) {
            setAllRows(tariffCodeState.companyTariffCodes?.map((companyTariffCodeEntity, index) => ({
                ...companyTariffCodeEntity,
                index: index || 0,
                flag_showCutCursor: false,
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, tariffCodeState.companyTariffCodes]);

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isSliderOpen]);

    useEffect(() => {
        if (JSON.stringify(tariffCodeState.companyTariffCodes?.map((companyTariffCodeEntity, index) => ({
            ...companyTariffCodeEntity,
            index: index || 0,
            flag_showCutCursor: false,
        }))) !== JSON.stringify(allRows)) {
            setAllRows(tariffCodeState.companyTariffCodes?.map((companyTariffCodeEntity, index) => ({
                ...companyTariffCodeEntity,
                index: index || 0,
                flag_showCutCursor: false,
            })));
        }
    }, [allRows, tariffCodeState.companyTariffCodes]);

    return <>
        <div className='empty-form'>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        </div>
        {<TableWrapper>
            {!initialAllRows && memoTariffCodeComponentTable}
            {tariffCodeState.forceRefresh && <></>}
        </TableWrapper>}
        
    </>;
}

export default memo(TariffCodeByCompanyDetailTablePanel);