import { useTariffCodeByCompanyTracked } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider";
import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffCodeByCompanyDetailTablePanel from "../Table/TariffCodeByCompanyDetailTablePanel";
import { TariffCodeByCompanyDetailFormPanel } from "./TariffCodeByCompanyDetailFormPanel";

const TariffCodeByCompanyMaintenance: React.FC = () => {
    const [tariffCodeByCompanyState] = useTariffCodeByCompanyTracked();
    const { detailState } = tariffCodeByCompanyState;
    const { isSliderOpen } = detailState

    return <>
        <div className={`main-comp-wrapper${(isSliderOpen) ? '' : ' im-hide-side-form-draggable'}`}>
            {/* <TariffCodeByCompanyTitleBar/> */}            
            <SliderPanel
                isOpen={isSliderOpen}
                draggable={false}
                leftSectionWidth={isSliderOpen ? "50%" : "100%"}
                rightSectionWidth={"50%"}
                leftChildren={<TariffCodeByCompanyDetailTablePanel />}
                rightChildren={<TariffCodeByCompanyDetailFormPanel />}
            />
        </div>
    </>
}

export default memo(TariffCodeByCompanyMaintenance);