
import { CompanyEntity, EMPTY_COMPANY_ENTITY } from "domain/entity/Company/CompanyEntity";
import { CompanyTariffCodeEntity, EMPTY_COMPANY_TARIFF_CODE_ENTITY } from "domain/entity/TariffCode/CompanyTariffCodeEntity";
import { EMPTY_TARIFF_CODE_COMPANY_REQUEST_ENTITY, TariffCodeCompanyRequestEntity } from "domain/entity/TariffCode/TariffCodeCompanyRequestEntity";
import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TariffCodeDropdownOptions {
    companyDropdownOptions: DropdownProps[],
    parentTariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
}
export interface MasterChangeState extends BaseViewChangeSate {
    tariffCompanyRequest: TariffCodeCompanyRequestEntity,
}

export interface DetailChangeState extends BaseViewChangeSate {
    // companyTariffCodes: CompanyTariffCodeEntity[],
    isRefreshInitData: boolean,
    currentSelectItem: CompanyTariffCodeEntity,
    editingItem: CompanyTariffCodeEntity,
    selectedRows: CompanyTariffCodeEntity[],
    updatedRows: CompanyTariffCodeEntity[],
}

export interface TariffCodeByCompanyModel {
    isLoading: boolean,
    isShowAddPanel: boolean,
    isShowDetail: boolean,
    companyEntityList: CompanyEntity[],
    currentSelectedRow: CompanyEntity,
    selectedRows: CompanyEntity[],
    dynamicOptions: TariffCodeDropdownOptions,
    isBackFromDetail: boolean,
    subMenuItemArray: MenuItem[],
    masterState: MasterChangeState,
    detailState: DetailChangeState,
    companyTariffCodes: CompanyTariffCodeEntity[],
    forceRefresh: boolean,
}

export const EMPTY_TARIFF_CODE_BY_COMPANY_MODEL: TariffCodeByCompanyModel = {
    isLoading: false,
    isShowDetail: false,
    isShowAddPanel: false,
    companyEntityList: [],
    currentSelectedRow: { ...EMPTY_COMPANY_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        parentTariffCodeDropdownOptions: {},
        companyDropdownOptions: []
    },
    isBackFromDetail: false,
    subMenuItemArray: [],
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        tariffCompanyRequest: EMPTY_TARIFF_CODE_COMPANY_REQUEST_ENTITY
    },
    detailState: {
        //companyTariffCodes: [],
        isRefreshInitData: true,
        currentSelectItem: EMPTY_COMPANY_TARIFF_CODE_ENTITY,
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        selectedRows: [],
        editingItem: EMPTY_COMPANY_TARIFF_CODE_ENTITY,
        updatedRows: []
    },
    companyTariffCodes: [],
    forceRefresh: false
}